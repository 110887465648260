// const BASE_PROXY_URL = "http://43.205.63.150:8000"; // development URL
const BASE_PROXY_URL = "https://api.docur.io"; // development URL

// export const IMAGE_PROXY_URL = "http://43.205.63.150:81";
export const IMAGE_PROXY_URL = "https://api.docur.io";

export const HASH_URL = "http://13.200.195.38:4444/docur/transaction";

//Authentication
export const POST_SEND_OTP = `${BASE_PROXY_URL}/account/sendotp`;
export const POST_LOGIN = `${BASE_PROXY_URL}/account/login`;
export const GET_USER_DETAIL = `${BASE_PROXY_URL}/account/getuser`;
export const GET_ALL_USERS = `${BASE_PROXY_URL}/account/allusers`;
export const GET_ALL_USERS_OPEN = `${BASE_PROXY_URL}/account/allusersopen`;
export const POST_LOGOUT = `${BASE_PROXY_URL}/account/logout`;

// Profile
export const POST_ADD_INDV_PROFILE = `${BASE_PROXY_URL}/account/userdetail`;
export const POST_ADD_ORG_PROFILE = `${BASE_PROXY_URL}/account/company`;

// documents
export const POST_UPLOAD_DOCUMENTS = `${BASE_PROXY_URL}/account/file`;  
export const PATCH_UPLOAD_DOCUMENTS = `${BASE_PROXY_URL}/account/file`;
export const DELETE_DOCUMENTS = `${BASE_PROXY_URL}/account/file`;
export const GET_DOCUMENTS = `${BASE_PROXY_URL}/account/file`;
export const POST_CREATE_FOLDER = `${BASE_PROXY_URL}/account/folder`;
export const GET_CREATED_FOLDER = `${BASE_PROXY_URL}/account/folder`;
export const PATCH_ADD_FILE_TO_FOLDER = `${BASE_PROXY_URL}/account/file`;
export const POST_SHARE_DOCUMENT = `${BASE_PROXY_URL}/account/share`;
export const GET_SHARE_DOCUMENT = `${BASE_PROXY_URL}/account/share`;

// landing
export const POST_CONTACT_US = `${BASE_PROXY_URL}/account/contact-us`; 