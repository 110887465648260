import axios from "axios";
import {
  POST_ADD_INDV_PROFILE,
  POST_ADD_ORG_PROFILE,
} from "../../services/Api";
import {
  ADD_INDV_PROFILE_FAILURE,
  ADD_INDV_PROFILE_IN_ORG_FAILURE,
  ADD_INDV_PROFILE_IN_ORG_SUCCESS,
  ADD_INDV_PROFILE_SUCCESS,
  ADD_ORG_PROFILE_FAILURE,
  ADD_ORG_PROFILE_SUCCESS,
} from "./profile.actionTypes";
import { toast } from "react-toastify";

export const addIndvProfile = (data) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("token")) || null;

  const formData = new FormData();
  formData.append("contact_email", data.contact_email);
  formData.append("name", data.name);
  formData.append("gender", data.gender);
  formData.append("mobile", data.mobile);
  formData.append("mobile2", data.mobile2);
  formData.append("country", data?.country?.label);
  formData.append("image", data.image);
  formData.append("profile_type", "individual");

  const headers = {
    Authorization: `Bearer ${token}`,
  };
  try {
    const resp = await axios.post(POST_ADD_INDV_PROFILE, formData, { headers });
    if (resp.status === 201) {
      toast.success("profile successfully submitted", {
        autoClose: 1500,
      });
      dispatch({
        type: ADD_INDV_PROFILE_SUCCESS,
        payload: resp?.data,
      });
    } else {
      toast.error("Please try again...", {
        autoClose: 1500,
      });
      dispatch({
        type: ADD_INDV_PROFILE_FAILURE,
        payload: {
          message: resp?.data?.msg,
        },
      });
    }
  } catch (error) {
    toast.error("Please try again...", {
      autoClose: 1500,
    });
    dispatch({
      type: ADD_INDV_PROFILE_FAILURE,
      payload: {
        message: error,
      },
    });
  }
};
export const addOrgProfile = (data, userData) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("token")) || null;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const resp = await axios.post(POST_ADD_ORG_PROFILE, data, { headers });
    if (resp.data) {
      const companyId = resp?.data?.id;
      dispatch(addIndvProfileInOrg(userData, companyId))
      dispatch({
        type: ADD_ORG_PROFILE_SUCCESS,
        payload: resp?.data,
      });
     
    } else {
      dispatch({
        type: ADD_ORG_PROFILE_FAILURE,
        payload: {
          message: resp?.data?.msg,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: ADD_ORG_PROFILE_FAILURE,
      payload: {
        message: error,
      },
    });
  }
};

export const addIndvProfileInOrg = (userData, companyId) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("token")) || null;

  const formData = new FormData();
  formData.append("contact_email", userData.contact_email);
  formData.append("name", userData.name);
  formData.append("mobile", userData.contact);
  formData.append("designation", userData.designation);
  formData.append("image", userData.image);
  formData.append("company", companyId);

  const headers = {
    Authorization: `Bearer ${token}`,
  };
  try {
    const resp = await axios.post(POST_ADD_INDV_PROFILE, formData, { headers });
    if (resp.data) {
      toast.success("profile successfully submitted", {
        autoClose: 1000,
      });
      dispatch({
        type: ADD_INDV_PROFILE_IN_ORG_SUCCESS,
        payload: resp?.data,
      });
    } else {
      dispatch({
        type: ADD_INDV_PROFILE_IN_ORG_FAILURE,
        payload: {
          message: resp?.data?.msg,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: ADD_INDV_PROFILE_FAILURE,
      payload: {
        message: error,
      },
    });
  }
};

